import {
    RawHtml,
    Gutter,
    CallToAction,
    Video,
    Image,
    ImageLink,
    useTracking,
} from '$shared/components';
import React, { useId, useRef } from 'react';
import { ImageMedia, M70MediaItem, M75MediaAndTextModule } from '~/lib/data-contract';
import { useGalleryModal } from '~/shared/hooks/useGalleryModal/useGalleryModalState';
import { withErrorBoundary } from '~/shared/utils/errorBoundary';
import { Headline } from '../Headline/Headline';
import { ModuleContainer } from '../ModuleContainer';
import { StyledM75, StyledM75Content, StyledM75Image, StyledSubheadline } from './styled';
import { ModalGallery } from '$shared/components/ModalGallery';
import { useTranslation } from '$shared/utils/translation';
import { useInView } from 'react-cool-inview';
import { CursorFollowButton } from '../M70Media/components/CursorFollowButton';

export type M75MediaTextProps = M75MediaAndTextModule;

export const M75MediaText = ({
    pageElementIndex,
    id,
    image,
    headline,
    subHeadline,
    text,
    callToAction,
    secondaryCallToAction,
    mediaHorizontalAlignment = 'left',
    horizontalAlignment = 'left',
    video,
    ...rest
}: M75MediaTextProps) => {
    const { show } = useGalleryModal();
    const { translate } = useTranslation();
    const { trackLink } = useTracking();
    const mediaContainerRef = useRef<HTMLDivElement>(null);

    const galleryId = useId();

    const { observe, inView } = useInView({
        unobserveOnEnter: true,
        rootMargin: '100px',
    });

    return (
        <ModuleContainer ref={observe} pageElementIndex={pageElementIndex} {...rest}>
            <Gutter>
                <StyledM75 mediaAlignment={mediaHorizontalAlignment}>
                    <StyledM75Content
                        contentAlignment={horizontalAlignment}
                        mediaHorizontalAlignment={mediaHorizontalAlignment}
                    >
                        {subHeadline ? (
                            <StyledSubheadline variant="caption" children={subHeadline} />
                        ) : null}
                        {headline ? <Headline children={headline} variant="display3" /> : null}
                        {text ? <RawHtml html={text} removeLastChildMargin /> : null}
                        {callToAction ? (
                            <div>
                                <CallToAction callToAction={callToAction} itemHeadline={headline} />
                            </div>
                        ) : null}
                        {secondaryCallToAction ? (
                            <div>
                                <CallToAction callToAction={secondaryCallToAction} />
                            </div>
                        ) : null}
                    </StyledM75Content>
                    <StyledM75Image ref={mediaContainerRef}>
                        {!video && image?.src ? (
                            callToAction?.url ? (
                                <ImageLink
                                    withBorderRadius
                                    imageProps={{
                                        alt: headline,
                                        ...image,
                                        objectFit: 'cover',
                                        layout: 'fill',
                                        draggable: false,
                                    }}
                                    target={callToAction.target}
                                    linkProps={{
                                        href: callToAction.url,
                                    }}
                                    onClick={() => trackLink(callToAction, headline)}
                                />
                            ) : (
                                <RenderImage image={image} headline={headline} />
                            )
                        ) : null}

                        {image && video && video.src && !video.autoPlay && (
                            <>
                                <Image
                                    alt={headline}
                                    {...image}
                                    height={image.height}
                                    width={image.width}
                                    objectFit="cover"
                                    layout="fill"
                                    draggable={false}
                                />
                                <CursorFollowButton onClick={() => show(galleryId, id)}>
                                    {translate('Kompan.Generic.Play')}
                                </CursorFollowButton>
                            </>
                        )}

                        {image && video && video.src && video.autoPlay && inView && (
                            <Video
                                muted={true}
                                controls={false}
                                src={video.src}
                                loop={video.loop}
                                playing={video.autoPlay}
                                posterSrc={image?.src}
                                cover={true}
                                volumn={0.5}
                            />
                        )}
                    </StyledM75Image>
                </StyledM75>
            </Gutter>
            {image && video?.src && !video?.autoPlay && (
                <ModalGallery
                    galleryId={galleryId}
                    items={[
                        {
                            caption: headline ?? '',
                            id: id,
                            image: image,
                            externalVideoSrc: video?.src,
                        } as M70MediaItem,
                    ]}
                />
            )}
        </ModuleContainer>
    );
};

const RenderImage = ({ image, headline }: { image: ImageMedia; headline?: string }) => {
    return (
        <Image
            alt={headline}
            {...image}
            height={image.height}
            width={image.width}
            objectFit="cover"
            layout="fill"
            draggable={false}
        />
    );
};

export default withErrorBoundary(M75MediaText);
