import { breakpoints } from '$theme';
import styled from '@emotion/styled';
import { transparentize } from 'color2k';
import { ifProp } from 'styled-tools';
import { HorizontalAlignment, KompanHorizontalAlignment } from '~/lib/data-contract';
import { Text } from '~/shared/components';
import { M75MediaTextProps } from './M75MediaText';

type StyledM75Props = Pick<M75MediaTextProps, 'backgroundColor'>;

export const StyledM75 = styled.div<{ mediaAlignment: HorizontalAlignment }>(
    ({ mediaAlignment, theme }) => ({
        position: 'relative',
        width: '100%',
        display: 'flex',
        flexDirection: 'column-reverse',
        gap: theme.spaces[7],
        [breakpoints.sm]: {
            flexDirection: mediaAlignment === 'left' ? 'row' : 'row-reverse',
            alignItems: 'center',
        },
    })
);

export const StyledM75Content = styled.div<{
    contentAlignment: KompanHorizontalAlignment;
    mediaHorizontalAlignment: HorizontalAlignment;
}>(
    ({ contentAlignment, theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: contentAlignment === 'left' ? 'flex-start' : 'center',
        textAlign: contentAlignment,
        flex: 1,

        [breakpoints.md]: {
            maxWidth: 'calc(50% - 1.6rem)',
        },

        '> *': {
            marginBottom: theme.spaces[4],
            '&:last-child': {
                marginBottom: 0,
            },
        },
    }),
    ifProp({ contentAlignment: 'left' }, ({ mediaHorizontalAlignment }) => ({
        [breakpoints.sm]: {
            paddingRight: mediaHorizontalAlignment == 'left' ? 20 : 0,
            paddingLeft: mediaHorizontalAlignment == 'right' ? 20 : 0,
        },
        [breakpoints.md]: {
            paddingRight: mediaHorizontalAlignment == 'left' ? 70 : 0,
            paddingLeft: mediaHorizontalAlignment == 'right' ? 70 : 0,
        },
    })),
    ifProp({ contentAlignment: 'right' }, ({ mediaHorizontalAlignment }) => ({
        [breakpoints.sm]: {
            paddingRight: mediaHorizontalAlignment == 'left' ? 20 : 0,
            paddingLeft: mediaHorizontalAlignment == 'right' ? 20 : 0,
        },
        [breakpoints.md]: {
            paddingRight: mediaHorizontalAlignment == 'left' ? 70 : 0,
            paddingLeft: mediaHorizontalAlignment == 'right' ? 70 : 0,
        },
    })),
    ifProp(
        { contentAlignment: 'center' },
        {
            paddingLeft: 20,
            paddingRight: 20,
            [breakpoints.md]: {
                paddingLeft: 40,
                paddingRight: 40,
            },
        }
    )
);

export const StyledSubheadline = styled(Text)(({ theme }) => ({
    color: theme.colors.grey60,
}));

export const StyledM75Image = styled.div<StyledM75Props>({
    minHeight: '400px',
    maxHeight: '520px',
    position: 'relative',
    overflow: 'hidden',
    /* Half the full width(50vw) minus half the side padding and gap(75px) divied with ratio (670/520 = 1.288) to get heoght  */
    height: 'calc((50vw - 75px) / 1.288)',
    borderRadius: '45px 15px',

    [breakpoints.sm]: {
        flex: 1,
    },
    [breakpoints.md]: {
        flex: 1,
    },
    img: {
        borderRadius: '45px 15px',
    },
});

export const StyledButtonWrapper = styled.div({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    minWidth: 200,
    '> div': {
        width: '100%',
    },
});

export const StyledPlayerButton = styled.div(({ theme }) => ({
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 100,
    width: 100,
    top: 'calc(50% - 50px)',
    left: 'calc(50% - 50px)',
    minWidth: 100,
    borderRadius: '50%',
    color: theme.colors.black100,
    backgroundColor: transparentize(theme.colors.white100, 0.2),
    transition: 'background-color 0.3s ease',
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: transparentize(theme.colors.white100, 0.1),
    },
}));
